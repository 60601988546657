const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const blobToBase64 = async (blobUrl) => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export const generateStreamingResponse = async (messages, userProfile, onChunk) => {
  try {
    const formattedMessages = await Promise.all(messages.map(async (msg) => {
      let content = msg.text;
      
      if (msg.images && msg.images.length > 0) {
        content = [{ type: "text", text: msg.text }];
        for (const image of msg.images) {
          const base64Image = await blobToBase64(image);
          content.push({ 
            type: "image_url", 
            image_url: { "url": base64Image }
          });
        }
      }

      return {
        role: msg.sender === 'user' ? 'user' : 'assistant',
        content: content,
      };
    }));

    // Add user profile information as a system message
    const profileMessage = {
      role: 'system',
      content: `User Profile:
        Name: ${userProfile.name}
        Age Range: ${userProfile.ageRange}
        Gender: ${userProfile.gender}
        Style Preferences: ${userProfile.stylePreferences.join(', ')}
        Skin Type: ${userProfile.skinType}
        Hair Type: ${userProfile.hairType}
        Please tailor your responses based on this user profile.`
    };

    formattedMessages.unshift(profileMessage);

    const response = await fetch('https://api.openai.com/v1/chat/completions', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${API_KEY}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        model: 'gpt-4-vision-preview',
        messages: formattedMessages,
        max_tokens: 4000,
        stream: true,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reader = response.body.getReader();
    const decoder = new TextDecoder('utf-8');
    let buffer = '';

    while (true) {
      const { done, value } = await reader.read();
      if (done) break;

      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop();

      for (const line of lines) {
        if (line.startsWith('data: ')) {
          const data = line.slice(6);
          if (data === '[DONE]') {
            return;
          }
          try {
            const parsed = JSON.parse(data);
            const content = parsed.choices[0].delta.content;
            if (content) {
              onChunk(content);
            }
          } catch (error) {
            console.error('Error parsing SSE message:', error);
          }
        }
      }
    }
  } catch (error) {
    console.error('Error calling OpenAI API:', error);
    throw error;
  }
};