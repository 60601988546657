import React, { useState, useCallback, useRef, useEffect } from 'react';
import { BarChart2, Shirt, Compass, Lightbulb, Send, Image, Menu, User, Moon, Sun, X, PlusCircle, Search, ChevronLeft, LogOut } from 'lucide-react';
import { generateStreamingResponse } from '../services/openai';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import UserProfile from './UserProfile';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../firebase';
import { collection, addDoc, query, orderBy, onSnapshot, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';

const FeatureButton = ({ icon: Icon, label, isActive, onClick }) => {
  const { darkMode } = useAuth();
  return (
    <button 
      className={`flex items-center justify-center space-x-2 py-2 px-4 rounded-full transition-all duration-300 ${
        isActive 
          ? darkMode ? 'bg-avacasa-600 text-white' : 'bg-avacasa-500 text-white'
          : darkMode ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={onClick}
    >
      <Icon size={18} />
      <span className="text-sm font-medium hidden md:inline">{label}</span>
    </button>
  );
};

const MobileFeatureButton = ({ icon: Icon, label, isActive, onClick }) => {
  const { darkMode } = useAuth();
  return (
    <button 
      className={`flex flex-col items-center justify-center space-y-1 py-2 px-3 rounded-full transition-all duration-300 ${
        isActive 
          ? darkMode ? 'bg-avacasa-600 text-white' : 'bg-avacasa-500 text-white'
          : darkMode ? 'text-gray-300 hover:bg-gray-800' : 'text-gray-600 hover:bg-gray-100'
      }`}
      onClick={onClick}
    >
      <Icon size={18} />
      <span className="text-xs font-medium">{label}</span>
    </button>
  );
};

const Message = ({ text, sender, images, userProfileImage }) => {
  const { darkMode } = useAuth();
  return (
    <div className={`flex ${sender === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
      <div className={`flex items-end max-w-[70%] ${sender === 'user' ? 'flex-row-reverse' : 'flex-row'}`}>
        <div className={`w-8 h-8 rounded-full overflow-hidden shadow-md flex-shrink-0 ${
          sender === 'user' ? 'ml-2' : 'mr-2'
        }`}>
          {sender === 'user' && userProfileImage ? (
            <img src={userProfileImage} alt="User" className="w-full h-full object-cover" />
          ) : (
            <div className={`w-full h-full flex items-center justify-center ${
              sender === 'user' 
                ? 'bg-gradient-to-br from-avacasa-400 to-avacasa-600'
                : 'bg-gradient-to-br from-gray-500 to-gray-700'
            }`}>
              <span className="text-white text-sm font-bold">{sender === 'user' ? 'U' : 'A'}</span>
            </div>
          )}
        </div>
        <div className={`px-4 py-3 rounded-2xl ${
          sender === 'user' 
            ? darkMode ? 'bg-avacasa-600 text-white' : 'bg-avacasa-500 text-white'
            : darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800'
        }`}>
          <ReactMarkdown 
            remarkPlugins={[remarkGfm]} 
            className="text-sm leading-relaxed markdown-content"
            components={{
              p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
              ul: ({node, ...props}) => <ul className="list-disc pl-4 mb-2" {...props} />,
              ol: ({node, ...props}) => <ol className="list-decimal pl-4 mb-2" {...props} />,
              li: ({node, ...props}) => <li className="mb-1" {...props} />,
            }}
          >
            {text}
          </ReactMarkdown>
          {images && images.length > 0 && (
            <div className="mt-2 grid grid-cols-2 gap-2">
              {images.map((image, index) => (
                <img key={index} src={image} alt={`Uploaded ${index + 1}`} className="rounded-lg max-w-full h-auto max-h-48" />
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Sidebar = ({ isOpen, onClose, onNewConsultation, conversations, onSelectConversation, onDeleteConversation, onEditConversationName }) => {
  const { darkMode } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredConversations = conversations.filter(conv => 
    conv.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    conv.messages.some(msg => msg.text.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className={`fixed inset-y-0 left-0 z-30 w-64 ${darkMode ? 'bg-gray-800' : 'bg-white'} overflow-y-auto transition-transform duration-300 ease-in-out transform ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <div className="p-4">
        <div className="flex items-center justify-between mb-4">
          <h1 className={`text-xl font-light tracking-wide ${darkMode ? 'text-avacasa-400' : 'text-avacasa-600'}`}>AVACASA</h1>
          <button onClick={onClose} className="p-1 rounded-full hover:bg-gray-200 dark:hover:bg-gray-700">
            <ChevronLeft size={24} className={darkMode ? 'text-white' : 'text-gray-800'} />
          </button>
        </div>
        <button
          onClick={onNewConsultation}
          className={`w-full py-2 px-4 mb-4 rounded-full flex items-center justify-center ${
            darkMode ? 'bg-avacasa-600 text-white hover:bg-avacasa-700' : 'bg-avacasa-500 text-white hover:bg-avacasa-600'
          }`}
        >
          <PlusCircle size={18} className="mr-2" />
          New Consultation
        </button>
        <div className="relative mb-4">
          <input
            type="text"
            placeholder="Search conversations..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className={`w-full py-2 px-4 pr-10 rounded-full ${
              darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'
            }`}
          />
          <Search size={18} className={`absolute right-3 top-1/2 transform -translate-y-1/2 ${darkMode ? 'text-gray-400' : 'text-gray-500'}`} />
        </div>
        <div className="space-y-2">
          {filteredConversations.map((conv) => (
            <div key={conv.id} className={`p-2 rounded-lg cursor-pointer ${darkMode ? 'hover:bg-gray-700' : 'hover:bg-gray-100'}`}>
              <div className="flex justify-between items-center">
                <span 
                  onClick={() => onSelectConversation(conv.id)}
                  className={`flex-grow truncate ${darkMode ? 'text-white' : 'text-gray-800'}`}
                >
                  {conv.name}
                </span>
                <button 
                  onClick={() => onDeleteConversation(conv.id)}
                  className={`ml-2 ${darkMode ? 'text-gray-400 hover:text-red-400' : 'text-gray-500 hover:text-red-500'}`}
                >
                  <X size={16} />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const Avacasa = () => {
  const [activeFeature, setActiveFeature] = useState('chat');
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const { currentUser, darkMode, toggleDarkMode, logout } = useAuth();
  const fileInputRef = useRef(null);
  const contentRef = useRef(null);
  const messagesEndRef = useRef(null);
  const [contentHeight, setContentHeight] = useState('100%');
  const [streamingMessage, setStreamingMessage] = useState('');
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const navigate = useNavigate();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages, streamingMessage]);

  useEffect(() => {
    const updateContentHeight = () => {
      if (contentRef.current) {
        const windowHeight = window.innerHeight;
        const headerHeight = document.querySelector('header').offsetHeight;
        const mobileNavHeight = document.querySelector('.mobile-nav')?.offsetHeight || 0;
        const footerHeight = document.querySelector('footer').offsetHeight;
        const newHeight = windowHeight - headerHeight - mobileNavHeight - footerHeight;
        setContentHeight(`${newHeight}px`);
      }
    };

    const handleResize = () => {
      updateContentHeight();
      const newIsDesktop = window.innerWidth >= 768;
      setIsDesktop(newIsDesktop);
      setIsSidebarOpen(newIsDesktop);
    };

    handleResize(); // Set initial state
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, `users/${currentUser.uid}/conversations`),
      orderBy('updatedAt', 'desc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const conversationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setConversations(conversationsData);
    });

    return unsubscribe;
  }, [currentUser]);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const startNewConsultation = async () => {
    const newConversation = {
      name: 'New Consultation',
      messages: [],
      createdAt: new Date(),
      updatedAt: new Date()
    };

    const docRef = await addDoc(collection(db, `users/${currentUser.uid}/conversations`), newConversation);
    setCurrentConversationId(docRef.id);
    setMessages([]);
  };

  const selectConversation = (id) => {
    setCurrentConversationId(id);
    const conversation = conversations.find(conv => conv.id === id);
    setMessages(conversation.messages);
    if (!isDesktop) {
      setIsSidebarOpen(false);
    }
  };

  const deleteConversation = async (id) => {
    await deleteDoc(doc(db, `users/${currentUser.uid}/conversations`, id));
    if (currentConversationId === id) {
      setCurrentConversationId(null);
      setMessages([]);
    }
  };

  const editConversationName = async (id, newName) => {
    await updateDoc(doc(db, `users/${currentUser.uid}/conversations`, id), { name: newName });
  };

  const handleSaveProfile = async (profileData) => {
    await updateDoc(doc(db, 'users', currentUser.uid), profileData);
  };

  const handleSend = useCallback(async () => {
    if (!currentUser || (!input.trim() && imagePreviews.length === 0)) return;
  
    const newUserMessage = { text: input, sender: "user", images: imagePreviews };
  
    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInput('');
    setImagePreviews([]);
    setIsProcessing(true);
    setStreamingMessage('');
  
    let conversationRef;
    if (currentConversationId) {
      conversationRef = doc(db, `users/${currentUser.uid}/conversations`, currentConversationId);
      await updateDoc(conversationRef, {
        messages: [...messages, newUserMessage],
        updatedAt: new Date()
      });
    } else {
      const newConversation = {
        name: input.substring(0, 30),
        messages: [newUserMessage],
        createdAt: new Date(),
        updatedAt: new Date()
      };
      conversationRef = await addDoc(collection(db, `users/${currentUser.uid}/conversations`), newConversation);
      setCurrentConversationId(conversationRef.id);
    }
  
    try {
      let fullResponse = '';
      await generateStreamingResponse([...messages, newUserMessage], currentUser, (chunk) => {
        fullResponse += chunk;
        setStreamingMessage(prev => prev + chunk);
      });
  
      const aiMessage = { text: fullResponse, sender: "ai" };
      
      await updateDoc(conversationRef, {
        messages: [...messages, newUserMessage, aiMessage],
        updatedAt: new Date()
      });
      
      setMessages(prevMessages => [...prevMessages, aiMessage]);
    } catch (error) {
      console.error('Error generating AI response:', error);
      const errorMessage = { text: "I'm sorry, I encountered an error. Please try again.", sender: "ai" };
      
      await updateDoc(conversationRef, {
        messages: [...messages, newUserMessage, errorMessage],
        updatedAt: new Date()
      });
      
      setMessages(prevMessages => [...prevMessages, errorMessage]);
    } finally {
      setIsProcessing(false);
      setStreamingMessage('');
    }
  }, [input, messages, imagePreviews, currentConversationId, currentUser]);
  
  const handleImageUpload = async (event) => {
    const files = Array.from(event.target.files);
    if (files.length > 4) {
      alert("You can only upload up to 4 images at once.");
      return;
    }

    const uploadPromises = files.map(async (file) => {
      const imageRef = ref(storage, `images/${currentUser.uid}/${Date.now()}_${file.name}`);
      await uploadBytes(imageRef, file);
      return getDownloadURL(imageRef);
    });

    const uploadedUrls = await Promise.all(uploadPromises);
    setImagePreviews(prevPreviews => [...prevPreviews, ...uploadedUrls].slice(0, 4));
  };

  const removeImagePreview = (index) => {
    setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  return (
    <div className={`flex h-screen ${darkMode ? 'bg-gray-900' : 'bg-white'}`}>
      <Sidebar
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        onNewConsultation={startNewConsultation}
        conversations={conversations}
        onSelectConversation={selectConversation}
        onDeleteConversation={deleteConversation}
        onEditConversationName={editConversationName}
      />
      <div className="flex-1 flex flex-col">
        <header className={`${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-b px-4 py-3 flex items-center justify-between sticky top-0 z-10`}>
          <div className="flex items-center space-x-3">
            <button onClick={toggleSidebar}>
              <Menu size={22} className={darkMode ? 'text-avacasa-400' : 'text-avacasa-600'} />
            </button>
            <h1 className={`text-xl font-light tracking-wide ${darkMode ? 'text-avacasa-400' : 'text-avacasa-600'}`}>AVACASA</h1>
          </div>
          <div className="hidden md:flex items-center space-x-1 md:space-x-2">
            <FeatureButton icon={BarChart2} label="Analysis" isActive={activeFeature === 'analysis'} onClick={() => setActiveFeature('analysis')} />
            <FeatureButton icon={Shirt} label="Wardrobe" isActive={activeFeature === 'wardrobe'} onClick={() => setActiveFeature('wardrobe')} />
            <FeatureButton icon={Compass} label="Discovery" isActive={activeFeature === 'discovery'} onClick={() => setActiveFeature('discovery')} />
            <FeatureButton icon={Lightbulb} label="Inspiration" isActive={activeFeature === 'inspiration'} onClick={() => setActiveFeature('inspiration')} />
          </div>
          <div className="flex items-center space-x-2">
            <button onClick={toggleDarkMode} className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-avacasa-400' : 'bg-gray-100 text-avacasa-600'} hover:bg-opacity-80 transition-colors`}>
              {darkMode ? <Sun size={18} /> : <Moon size={18} />}
            </button>
            <button 
              onClick={() => setIsProfileOpen(true)}
              className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} hover:bg-opacity-80 transition-colors`}
            >
              <User size={18} />
            </button>
            <button 
              onClick={handleLogout}
              className={`p-2 rounded-full ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100 text-gray-800'} hover:bg-opacity-80 transition-colors`}
            >
              <LogOut size={18} />
            </button>
          </div>
        </header>

        <div className={`md:hidden flex justify-between w-full p-2 ${darkMode ? 'bg-gray-800' : 'bg-gray-100'} sticky top-[57px] z-10 mobile-nav`}>
          <MobileFeatureButton icon={BarChart2} label="Analysis" isActive={activeFeature === 'analysis'} onClick={() => setActiveFeature('analysis')} />
          <MobileFeatureButton icon={Shirt} label="Wardrobe" isActive={activeFeature === 'wardrobe'} onClick={() => setActiveFeature('wardrobe')} />
          <MobileFeatureButton icon={Compass} label="Discovery" isActive={activeFeature === 'discovery'} onClick={() => setActiveFeature('discovery')} />
          <MobileFeatureButton icon={Lightbulb} label="Inspiration" isActive={activeFeature === 'inspiration'} onClick={() => setActiveFeature('inspiration')} />
        </div>

        <main className="flex-1 overflow-hidden flex flex-col" ref={contentRef} style={{ height: contentHeight }}>
          <div className="flex-1 overflow-y-auto px-4 py-4 max-w-3xl mx-auto w-full">
            {messages.map((message, index) => (
              <Message 
                key={index} 
                text={message.text} 
                sender={message.sender} 
                images={message.images}
                userProfileImage={currentUser.profileImageUrl}
              />
            ))}
            {streamingMessage && (
              <Message text={streamingMessage} sender="ai" />
            )}
            {isProcessing && !streamingMessage && (
              <div className="flex justify-start mb-4">
                <div className={`px-4 py-3 rounded-2xl ${darkMode ? 'bg-gray-800 text-gray-200' : 'bg-gray-100 text-gray-800'}`}>
                  <div className="flex space-x-2">
                    <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse"></div>
                    <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse delay-75"></div>
                    <div className="w-2 h-2 rounded-full bg-gray-400 animate-pulse delay-150"></div>
                  </div>
                </div>
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
        </main>
        <footer className={`${darkMode ? 'bg-gray-800 border-gray-700' : 'bg-white border-gray-200'} border-t p-3 sticky bottom-0 z-10`}>
          <div className={`max-w-4xl mx-auto flex flex-col`}>
            {imagePreviews.length > 0 && (
              <div className="mb-2 flex items-center space-x-2 overflow-x-auto">
                {imagePreviews.map((preview, index) => (
                  <div key={index} className="relative">
                    <img src={preview} alt={`Preview ${index + 1}`} className="h-16 w-auto rounded-md" />
                    <button
                      onClick={() => removeImagePreview(index)}
                      className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full p-1 hover:bg-red-600 transition-colors"
                    >
                      <X size={12} />
                    </button>
                  </div>
                ))}
              </div>
            )}
            <div className={`flex items-center space-x-3 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'} rounded-full p-2`}>
              <input
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={fileInputRef}
                className="hidden"
                multiple
              />
              <button 
                onClick={() => fileInputRef.current.click()}
                className={`p-2 ${darkMode ? 'text-gray-400 hover:text-avacasa-400' : 'text-gray-400 hover:text-avacasa-600'} transition-colors`}
              >
                <Image size={18} />
              </button>
              <input
                type="text"
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                placeholder="Ask about your style..."
                className={`flex-1 bg-transparent border-none focus:outline-none ${darkMode ? 'text-white placeholder-gray-400' : 'text-gray-800 placeholder-gray-400'} text-sm`}
              />
              <button 
                onClick={handleSend} 
                className={`${darkMode ? 'bg-avacasa-600 text-white hover:bg-avacasa-700' : 'bg-avacasa-500 text-white hover:bg-avacasa-600'} p-2 rounded-full transition-colors`}
              >
                <Send size={18} />
              </button>
            </div>
          </div>
        </footer>
      </div>
      
      <UserProfile 
        isOpen={isProfileOpen} 
        onClose={() => setIsProfileOpen(false)} 
        darkMode={darkMode} 
        onSave={handleSaveProfile}
        currentUser={currentUser}
      />
    </div>
  );
};

export default Avacasa;