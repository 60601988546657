import React, { useState } from 'react';
import { auth, db, storage } from '../firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate, Link } from 'react-router-dom';
import { X, User, ArrowRight, ArrowLeft, Check, Mail, Lock, Camera } from 'lucide-react';

const Signup = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    ageRange: '',
    gender: '',
    stylePreferences: [],
    skinType: '',
    hairType: '',
    profileImage: null,
  });
  const [error, setError] = useState('');
  const [previewImage, setPreviewImage] = useState(null);
  const navigate = useNavigate();

  const ageRanges = ['18-24', '25-34', '35-44', '45-54', '55+'];
  const genders = ['Female', 'Male', 'Non-binary', 'Prefer not to say'];
  const styleOptions = ['Chic', 'Bohemian', 'Minimalist', 'Vintage', 'Streetwear', 'Glamorous', 'Preppy', 'Edgy', 'Classic', 'Romantic'];
  const skinTypes = ['Dry', 'Oily', 'Combination', 'Normal', 'Sensitive', 'Not sure'];
  const hairTypes = ['Straight', 'Wavy', 'Curly', 'Coily', 'Not sure'];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleStylePreferenceChange = (preference) => {
    setFormData(prev => {
      const updatedPreferences = prev.stylePreferences.includes(preference)
        ? prev.stylePreferences.filter(p => p !== preference)
        : [...prev.stylePreferences, preference].slice(0, 3);
      return { ...prev, stylePreferences: updatedPreferences };
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({ ...prev, profileImage: file }));
      setPreviewImage(URL.createObjectURL(file));
    }
  };

  const handleSignup = async () => {
    setError('');
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
      const user = userCredential.user;

      let profileImageUrl = null;
      if (formData.profileImage) {
        const imageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(imageRef, formData.profileImage);
        profileImageUrl = await getDownloadURL(imageRef);
      }

      const userProfile = {
        name: formData.name,
        ageRange: formData.ageRange,
        gender: formData.gender,
        stylePreferences: formData.stylePreferences,
        skinType: formData.skinType,
        hairType: formData.hairType,
        profileImageUrl,
        darkMode: false,
      };

      await setDoc(doc(db, 'users', user.uid), userProfile);
      navigate('/');
    } catch (error) {
      setError('Failed to create an account: ' + error.message);
      console.error(error);
    }
  };

  const nextStep = () => setStep(prev => Math.min(prev + 1, 4));
  const prevStep = () => setStep(prev => Math.max(prev - 1, 1));

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Create your stylish account</h3>
            <div className="space-y-4">
              <div className="relative">
                <Mail className="absolute top-3 left-3 text-gray-400" size={20} />
                <input
                  name="email"
                  type="email"
                  required
                  className="pl-10 pr-3 py-2 w-full border-b border-gray-300 focus:border-avacasa-500 focus:ring-0 bg-transparent text-gray-900"
                  placeholder="Email address"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              <div className="relative">
                <Lock className="absolute top-3 left-3 text-gray-400" size={20} />
                <input
                  name="password"
                  type="password"
                  required
                  className="pl-10 pr-3 py-2 w-full border-b border-gray-300 focus:border-avacasa-500 focus:ring-0 bg-transparent text-gray-900"
                  placeholder="Password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </div>
              <div className="relative">
                <User className="absolute top-3 left-3 text-gray-400" size={20} />
                <input
                  name="name"
                  type="text"
                  required
                  className="pl-10 pr-3 py-2 w-full border-b border-gray-300 focus:border-avacasa-500 focus:ring-0 bg-transparent text-gray-900"
                  placeholder="Full Name"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Tell us about yourself</h3>
            <div className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Age Range</label>
                <select
                  name="ageRange"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-avacasa-500 focus:border-avacasa-500"
                  value={formData.ageRange}
                  onChange={handleChange}
                >
                  <option value="">Select Age Range</option>
                  {ageRanges.map(range => (
                    <option key={range} value={range}>{range}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Gender</label>
                <select
                  name="gender"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-avacasa-500 focus:border-avacasa-500"
                  value={formData.gender}
                  onChange={handleChange}
                >
                  <option value="">Select Gender</option>
                  {genders.map(gender => (
                    <option key={gender} value={gender}>{gender}</option>
                  ))}
                </select>
              </div>
            </div>
          </>
        );
      case 3:
        return (
          <>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Define your style</h3>
            <p className="text-sm text-gray-600 mb-4">Select up to 3 styles that best describe you</p>
            <div className="flex flex-wrap gap-2 mb-6">
              {styleOptions.map(style => (
                <button
                  key={style}
                  type="button"
                  onClick={() => handleStylePreferenceChange(style)}
                  className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                    formData.stylePreferences.includes(style)
                      ? 'bg-avacasa-600 text-white'
                      : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                >
                  {style}
                </button>
              ))}
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Skin Type</label>
                <select
                  name="skinType"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-avacasa-500 focus:border-avacasa-500"
                  value={formData.skinType}
                  onChange={handleChange}
                >
                  <option value="">Select Skin Type</option>
                  {skinTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Hair Type</label>
                <select
                  name="hairType"
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-avacasa-500 focus:border-avacasa-500"
                  value={formData.hairType}
                  onChange={handleChange}
                >
                  <option value="">Select Hair Type</option>
                  {hairTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </>
        );
      case 4:
        return (
          <>
            <h3 className="text-2xl font-bold text-gray-900 mb-6">Show off your style</h3>
            <div className="flex flex-col items-center">
              {previewImage ? (
                <div className="relative">
                  <img src={previewImage} alt="Profile preview" className="w-40 h-40 rounded-full object-cover shadow-lg" />
                  <button
                    type="button"
                    onClick={() => {
                      setPreviewImage(null);
                      setFormData(prev => ({ ...prev, profileImage: null }));
                    }}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1 shadow-md hover:bg-red-600 transition-colors"
                  >
                    <X size={20} />
                  </button>
                </div>
              ) : (
                <div className="w-40 h-40 rounded-full bg-gray-200 flex items-center justify-center shadow-inner">
                  <Camera size={48} className="text-gray-400" />
                </div>
              )}
              <label htmlFor="profile-image" className="mt-6 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-avacasa-600 hover:bg-avacasa-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avacasa-500 cursor-pointer transition-colors">
                Upload a profile picture
                <input
                  id="profile-image"
                  name="profile-image"
                  type="file"
                  accept="image/*"
                  className="sr-only"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-avacasa-50 to-avacasa-100 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full">
        <div className="text-center mb-10">
          <h1 className="text-4xl font-extrabold text-avacasa-800 mb-2">AVACASA</h1>
          <p className="text-avacasa-600 text-lg">Your Personal AI Stylist</p>
        </div>
        <div className="bg-white shadow-2xl rounded-lg overflow-hidden">
          <div className="px-8 py-10">
            <div className="mb-8">
              <div className="w-full bg-gray-200 rounded-full h-1.5">
                <div
                  className="bg-avacasa-600 h-1.5 rounded-full transition-all duration-300 ease-out"
                  style={{ width: `${(step / 4) * 100}%` }}
                ></div>
              </div>
              <p className="text-center text-sm text-gray-500 mt-2">Step {step} of 4</p>
            </div>
            {renderStep()}
            {error && <p className="text-red-500 text-sm mt-4">{error}</p>}
            <div className="flex justify-between mt-8">
              {step > 1 && (
                <button
                  type="button"
                  onClick={prevStep}
                  className="flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-avacasa-600 bg-avacasa-100 hover:bg-avacasa-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avacasa-500 transition-colors"
                >
                  <ArrowLeft size={16} className="mr-2" />
                  Back
                </button>
              )}
              {step < 4 ? (
                <button
                  type="button"
                  onClick={nextStep}
                  className="flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-avacasa-600 hover:bg-avacasa-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avacasa-500 transition-colors"
                >
                  Next
                  <ArrowRight size={16} className="ml-2" />
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleSignup}
                  className="flex items-center justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-avacasa-600 hover:bg-avacasa-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-avacasa-500 transition-colors"
                >
                  Complete Signup
                  <Check size={16} className="ml-2" />
                </button>
              )}
            </div>
          </div>
        </div>
        <p className="mt-6 text-center text-sm text-gray-600">
          Already have an account?{' '}
          <Link to="/login" className="font-medium text-avacasa-600 hover:text-avacasa-500 transition-colors">
            Log in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Signup;