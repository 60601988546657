import React, { useState, useEffect } from 'react';
import { X, User, Camera, Save, Mail, Calendar, UserCircle, Droplet, Scissors } from 'lucide-react';
import { db, storage } from '../firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const UserProfile = ({ isOpen, onClose, darkMode, onSave }) => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState({
    name: '',
    email: '',
    ageRange: '',
    gender: '',
    stylePreferences: [],
    skinType: '',
    hairType: '',
    profileImage: null,
  });
  const [activeTab, setActiveTab] = useState('basic');

  const styleOptions = ['Chic', 'Bohemian', 'Minimalist', 'Vintage', 'Streetwear', 'Glamorous', 'Preppy', 'Edgy', 'Classic', 'Romantic'];
  const ageRanges = ['18-24', '25-34', '35-44', '45-54', '55+'];
  const genders = ['Female', 'Male', 'Non-binary', 'Prefer not to say'];
  const skinTypes = ['Dry', 'Oily', 'Combination', 'Normal', 'Sensitive', 'Not sure'];
  const hairTypes = ['Straight', 'Wavy', 'Curly', 'Coily', 'Not sure'];

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setProfile({
            name: userData.name || '',
            email: currentUser.email || '',
            ageRange: userData.ageRange || '',
            gender: userData.gender || '',
            stylePreferences: userData.stylePreferences || [],
            skinType: userData.skinType || '',
            hairType: userData.hairType || '',
            profileImage: userData.profileImageUrl || null,
          });
        }
      }
    };
    fetchUserProfile();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile(prev => ({ ...prev, [name]: value }));
  };

  const handleStylePreferenceChange = (preference) => {
    setProfile(prev => {
      const updatedPreferences = prev.stylePreferences.includes(preference)
        ? prev.stylePreferences.filter(p => p !== preference)
        : [...prev.stylePreferences, preference].slice(0, 3);
      return { ...prev, stylePreferences: updatedPreferences };
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfile(prev => ({ ...prev, profileImage: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSave = async () => {
    if (!currentUser) return;

    try {
      let profileImageUrl = profile.profileImage;
      if (profile.profileImage && profile.profileImage.startsWith('data:image')) {
        const imageRef = ref(storage, `profileImages/${currentUser.uid}`);
        const response = await fetch(profile.profileImage);
        const blob = await response.blob();
        await uploadBytes(imageRef, blob);
        profileImageUrl = await getDownloadURL(imageRef);
      }

      const userRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userRef, {
        name: profile.name,
        ageRange: profile.ageRange,
        gender: profile.gender,
        stylePreferences: profile.stylePreferences,
        skinType: profile.skinType,
        hairType: profile.hairType,
        profileImageUrl,
      });

      onSave({ ...profile, profileImageUrl });
      onClose();
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'basic':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div className="relative">
              <User className="absolute top-3 left-3 text-gray-400" size={20} />
              <input
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                className={`pl-10 pr-3 py-2 w-full border-b ${darkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-transparent'} focus:border-avacasa-500 focus:ring-0 text-lg`}
                placeholder="Your Name"
              />
            </div>
            <div className="relative">
              <Mail className="absolute top-3 left-3 text-gray-400" size={20} />
              <input
                type="email"
                name="email"
                value={profile.email}
                readOnly
                className={`pl-10 pr-3 py-2 w-full border-b ${darkMode ? 'border-gray-600 bg-gray-700 text-white' : 'border-gray-300 bg-transparent'} focus:border-avacasa-500 focus:ring-0 text-lg`}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Age Range</label>
              <div className="relative">
                <Calendar className="absolute top-3 left-3 text-gray-400" size={20} />
                <select
                  name="ageRange"
                  value={profile.ageRange}
                  onChange={handleChange}
                  className={`pl-10 pr-3 py-2 w-full rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                >
                  <option value="">Select Age Range</option>
                  {ageRanges.map(range => (
                    <option key={range} value={range}>{range}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Gender</label>
              <div className="relative">
                <UserCircle className="absolute top-3 left-3 text-gray-400" size={20} />
                <select
                  name="gender"
                  value={profile.gender}
                  onChange={handleChange}
                  className={`pl-10 pr-3 py-2 w-full rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                >
                  <option value="">Select Gender</option>
                  {genders.map(gender => (
                    <option key={gender} value={gender}>{gender}</option>
                  ))}
                </select>
              </div>
            </div>
          </motion.div>
        );
      case 'style':
        return (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="space-y-6"
          >
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-2">Style Preferences (Select up to 3)</label>
              <div className="flex flex-wrap gap-2">
                {styleOptions.map(style => (
                  <motion.button
                    key={style}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => handleStylePreferenceChange(style)}
                    className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                      profile.stylePreferences.includes(style)
                        ? 'bg-avacasa-600 text-white'
                        : darkMode ? 'bg-gray-700 text-gray-300 hover:bg-gray-600' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
                    }`}
                  >
                    {style}
                  </motion.button>
                ))}
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Skin Type</label>
              <div className="relative">
                <Droplet className="absolute top-3 left-3 text-gray-400" size={20} />
                <select
                  name="skinType"
                  value={profile.skinType}
                  onChange={handleChange}
                  className={`pl-10 pr-3 py-2 w-full rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                >
                  <option value="">Select Skin Type</option>
                  {skinTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-500 mb-1">Hair Type</label>
              <div className="relative">
                <Scissors className="absolute top-3 left-3 text-gray-400" size={20} />
                <select
                  name="hairType"
                  value={profile.hairType}
                  onChange={handleChange}
                  className={`pl-10 pr-3 py-2 w-full rounded ${darkMode ? 'bg-gray-700 text-white' : 'bg-gray-100'} border-none focus:ring-2 focus:ring-avacasa-500`}
                >
                  <option value="">Select Hair Type</option>
                  {hairTypes.map(type => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          </motion.div>
        );
      default:
        return null;
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 z-50 overflow-y-auto bg-black bg-opacity-50"
        >
          <div className="min-h-screen flex items-center justify-center p-4">
            <motion.div
              initial={{ scale: 0.9, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.9, opacity: 0 }}
              transition={{ type: 'spring', damping: 20, stiffness: 300 }}
              className={`w-full max-w-lg ${darkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-800'} rounded-lg shadow-xl overflow-hidden`}
            >
              <div className="p-6">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-3xl font-bold text-avacasa-600">Your Style Profile</h2>
                  <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    <X size={24} />
                  </button>
                </div>
                <div className="flex justify-center mb-8">
                  <div className="relative group">
                    <motion.div
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="w-32 h-32 rounded-full overflow-hidden bg-gray-200 shadow-lg"
                    >
                      {profile.profileImage ? (
                        <img src={profile.profileImage} alt="Profile" className="w-full h-full object-cover" />
                      ) : (
                        <div className="w-full h-full flex items-center justify-center">
                          <User size={64} className="text-gray-400" />
                        </div>
                      )}
                    </motion.div>
                    <label htmlFor="profile-image-upload" className="absolute bottom-0 right-0 bg-avacasa-500 text-white p-2 rounded-full cursor-pointer shadow-md hover:bg-avacasa-600 transition-colors">
                      <Camera size={20} />
                    </label>
                    <input
                      id="profile-image-upload"
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      className="hidden"
                    />
                  </div>
                </div>
                <div className="mb-6">
                  <div className="flex space-x-4 border-b border-gray-300">
                    <button
                      onClick={() => setActiveTab('basic')}
                      className={`py-2 px-4 font-medium ${activeTab === 'basic' ? 'text-avacasa-600 border-b-2 border-avacasa-600' : 'text-gray-500'}`}
                    >
                      Basic Info
                    </button>
                    <button
                      onClick={() => setActiveTab('style')}
                      className={`py-2 px-4 font-medium ${activeTab === 'style' ? 'text-avacasa-600 border-b-2 border-avacasa-600' : 'text-gray-500'}`}
                    >
                      Style Preferences
                    </button>
                  </div>
                </div>
                {renderTabContent()}
              </div>
              <div className={`px-6 py-4 ${darkMode ? 'bg-gray-900' : 'bg-gray-100'}`}>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleSave}
                  className="w-full flex items-center justify-center px-6 py-3 rounded-full bg-avacasa-600 hover:bg-avacasa-700 text-white transition-colors duration-300 text-lg font-medium"
                >
                  <Save size={20} className="mr-2" />
                  Save Profile
                </motion.button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default UserProfile;